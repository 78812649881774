import { Button, Grid, Paper } from "@mui/material";
import React from "react";

import Adetails from "./Adetails";
import { styled } from "@mui/material/styles";
import ChatAnnonce from "./Chat/ChatAnnonce";

export default function AnnonceDetails({ selectedAnnonce }) {
  const ShButton = styled(Button)`
    background-color: #1b9098;
    color: #fff;
    width: 80%;
    text-transform: uppercase;
    padding: 6px 12px;
    &:hover {
      background-color: #85c4c8;
    }
  `;

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Adetails annonce={selectedAnnonce} />
        </Grid>

        <Grid item xs={12} md={6} component={Paper}>
          <ChatAnnonce annonce={selectedAnnonce} />
        </Grid>
      </Grid>
    </>
  );
}
