import React, { useEffect, useState } from "react";
import Layout from "../../Components/layout/Layout";
import axios from "axios";
import CustomStepper from "./FormComponents/CustomStepper";
import AnnonceContext from "../../contexts/AnnonceContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Publier = ({ status }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentCategory, setCurrentCategory] = useState();
  const [formAttributes, setFormAttributes] = useState([]);
  const [imageUpload, setImageUpload] = useState();
  const [date, setDate] = useState(new Date().toISOString());
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => {
        navigate("/mes-annonces");
      },
    });
  };

  const [formAnnonce, setFormAnnonce] = useState({
    origin: status,
    status: status,
    ville: "",
  });
  useEffect(() => {
    setFormAnnonce({
      ...formAnnonce,
      annonceAttributes: formAttributes,
    });
  }, [formAttributes]);

  // upload des images
  const uploadImg = async (annonceId) => {
    const formData = new FormData();

    formData.append("file", imageUpload);
    try {
      await axios.post(
        process.env.REACT_APP_PUBLIC_API + `/annonces/${annonceId}/image`,
        formData
      );
    } catch (error) {
      console.log(error);
    }
  };

  // envoi de l'annonce en post

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PUBLIC_API + "/annonces",
        formAnnonce
      );

      const annonceId = response.data.id;

      uploadImg(annonceId);
      notifySuccess("Annonce ajoutée avec succès");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AnnonceContext.Provider
      value={{
        formAnnonce: formAnnonce,
        setFormAnnonce: setFormAnnonce,

        currentCategory: currentCategory,

        setCurrentCategory: setCurrentCategory,
        formAttributes: formAttributes,
        setFormAttributes: setFormAttributes,
        handleSubmit: handleSubmit,
        imageUpload: imageUpload,
        setImageUpload: setImageUpload,
        activeStep: activeStep,
        setActiveStep: setActiveStep,
        date: date,
        setDate: setDate,
        isComplete: isComplete,
        setIsComplete: setIsComplete,
      }}
    >
      <Layout>
        <ToastContainer />
        <CustomStepper />
      </Layout>
    </AnnonceContext.Provider>
  );
};

export default Publier;
