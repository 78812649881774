import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import DrawerComp from "./DrawerComp";
import Logo from "./Logo";

import Navbar from "./Navbar";

export default function Header({ customerId }) {
  const theme = useTheme();
  const isMatchMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {isMatchMd ? (
        <>
          <Grid container p={0}>
            <Grid item md={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
               
              >
              <DrawerComp  />
                <Logo customerId={customerId} width={200} />
              </Stack>

              {customerId === "2" && (
                <>
                  <Typography variant="h6" textAlign="center"> 
                    Un Objet perdu pendant le festival, c'est ici !
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Logo customerId={customerId} width={300} />
            {customerId === "2" && (
              <>
                <Typography variant="h5">
                  Un Objet perdu pendant le festival, c'est ici !
                </Typography>
              </>
            )}
          </Grid>
          <Grid item md={6}>
            <Navbar />
          </Grid>
        </Grid>
      )}
    </>
  );
}
