import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import AnnonceContext from "../../../../contexts/AnnonceContext";

export default function SelectField({
  data,
  parent,
  larg = 200,
  isSecret = false,
}) {
  const { setFormAttributes } = useContext(AnnonceContext);
  const [sousSelect, setSousSelect] = useState([]);
  const [isChild, setIsChild] = useState(false);

  //   useEffect(()=> {
  //     setIsChild(false);

  // }, [parent]);

  return (
    <div>
      <Autocomplete
        disablePortal
        id="combo-box"
        options={data}
        getOptionLabel={(option) => (option.label ? option.label : option.name)}
        onChange={(evt, val) => {
          if (val !== null) {
            if (val.attributes.length > 0) {
              setSousSelect(val);
              setIsChild(true);
            }

            setFormAttributes((selectAttributes) => [
              ...selectAttributes.filter(
                (att) => att.attribute !== parent.name
              ),
              {
                attribute: parent.name,
                attributeValue: val.name,
                isSecret: isSecret,
                uiLabel: data.uiLabel,
              },
            ]);
          } else {
            setIsChild(false);
          }
        }}
        sx={{ width: larg }}
        renderInput={(params) => <TextField {...params} label={parent.name} />}
      />

      {isChild && (
        <Autocomplete
          disablePortal
          id="combo-box-sousselect"
          options={sousSelect.attributes}
          getOptionLabel={(option) =>
            option.label ? option.label : option.name
          }
          onChange={(evt, val) => {
            if (val !== null) {
              setFormAttributes((selectAttributes) => [
                ...selectAttributes.filter(
                  (att) => att.attribute !== parent.name + " option"
                ),
                {
                  attribute: parent.name + " option",
                  attributeValue: val.name,
                  isSecret: isSecret,
                  uiLabel: val.uiLabel,
                },
              ]);
            }
          }}
          sx={{ width: larg }}
          renderInput={(params) => <TextField {...params} label="selection" />}
        />
      )}
    </div>
  );
}
