import React from "react";
import Header from "./Header";
import Container from "@mui/material/Container";

export default function Layout({ children, customerId }) {
  return (
    <>
      <Container p={0}
        maxWidth={false}
        sx={{ backgroundColor: "#fff", minHeight: "100vh" }}
      >
        <Header customerId={customerId} />
        {children}
      </Container>
    </>
  );
}
