import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component

import { Form } from "react-bootstrap";
import Iconify from "../../../../Components/Iconify";
import AuthContext from "../../../../contexts/AuthContext";
import authAPI from "../../../../services/authAPI";
import axios from "axios";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { setIsAuthenticated, setUser } = useContext(AuthContext);
  const { redeem } = useParams() || false;
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isForgotten, setIsForgotten] = useState(null);
  const [isPassResetSend, setIsPassResetSend] = useState(false);

  useEffect(() => {
    if (redeem === "pass") {
      setIsForgotten(true);
    }
  }, [redeem]);

  const getUser = async () => {
    try {
      const data = await axios
        .get(process.env.REACT_APP_PUBLIC_API + "/me")
        .then((response) => {
          setUser(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isForgotten) {
      if (credentials.username === "") return;
      try {
        const content = {
          email: credentials.username,
        };
        await authAPI.redeemPassWord(content);
        setIsAuthenticated(true);
        getUser();
        setError("");
        setIsSubmitting(true);
      } catch (error) {
        console.error(error);
        setError("erreur d'envoi");
      }
      setIsSubmitting(false);
      setIsPassResetSend(true);
    } else {
      try {
        await authAPI.authenticate(credentials);
        setIsAuthenticated(true);
        getUser();
        setError("");
        setIsSubmitting(true);

        const returnLink = window.localStorage.getItem("returnLinkMessage");
        if (returnLink) {
          window.localStorage.removeItem("returnLinkMessage");

          navigate(returnLink);
        } else {
          navigate("/mon-compte");
        }
      } catch (error) {
        console.error(error);
        setError("erreur de connexion");
      }
    }
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Veuillez saisir un email valide")
      .required("Email est obligatoire"),
    password: Yup.string().required("mot de passe obligatoire"),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleChange = (e) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <>
      {isPassResetSend ? (
        <>
          <Typography variant="h5">
            Votre demande de changement de mot de passe a été envoyée par mail
          </Typography>
          <Typography variant="h6">
            suivez les indications dans le mail, Merci !
          </Typography>
        </>
      ) : (
        <>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {isForgotten ? (
              <>
                <Typography variant="h4" gutterBottom>
                  Récupération de mot de passe
                </Typography>
                <Typography variant="h6" mb={1}>
                  Tapez votre adresse mail pour recréer un mot de passe{" "}
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Adresse Email"
                  name="username"
                  id="username"
                  onChange={handleChange}
                  value={credentials.username}
                />
                <LoadingButton
                  sx={{ marginTop: "20px" }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Envoyer
                </LoadingButton>
              </>
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  Connectez vous avec vos identifiants
                </Typography>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Adresse Email"
                    name="username"
                    id="username"
                    onChange={handleChange}
                    value={credentials.username}
                  />

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Mot de passe"
                    value={credentials.password}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Box>
                  <Typography
                    onClick={() => setIsForgotten(true)}
                    sx={{
                      cursor: "pointer",
                      color: "primary",
                    }}
                  >
                    Mot de passe oublié ?
                  </Typography>
                </Box>
                <LoadingButton
                  sx={{ marginTop: "20px" }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Connexion
                </LoadingButton>
              </>
            )}
          </Form>
        </>
      )}
    </>
  );
}
