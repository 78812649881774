import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Layout from "../../Components/layout/Layout";
import { useNavigate } from "react-router-dom";

export default function HomeCustomers({ id }) {
  // afficher les dates dans un select

  // aficher les categories d'objet

  // recuperation du client avec axios
  const [customer, setCustomer] = useState([]);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const [selectDay, setSelectDay] = useState();


  const handleSearch = () => {
  
    navigate(`/objets-partenaires/${customer.id}`);
  }

  useEffect(() => {
    fetchPartenaire();
  }, []);

  const fetchPartenaire = async () => {

    const data = await axios
      .get(process.env.REACT_APP_PUBLIC_API+`/customers/${id}`)
      .then((response) => response.data);

    setCustomer(data);
    setEvents(data.events[0].eventDays);
  };


  return (
    <Layout>
      <Grid container>
        <Grid md={6}>
          <Typography variant="h2">Un objet perdu pendant les</Typography>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/customers/logos/${customer.logoFilePath}`} alt=""
          />
        </Grid>

        <Grid md={6} sx={{ paddingX: "50px" }}>
          <Stack spacing={2}>
            <TextField label="Objet perdu..."></TextField>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"> Quel Jour </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectDay}
                label="Quel Jour"
                onChange={(e) => setSelectDay(e.target.value)}
              >
                {events.map((day) => (
                  <MenuItem value={day.date}>
                   {day.date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button sx={{ backgroundColor: "#4ABDF0", color: "#fff" }} 
            onClick={handleSearch}
            >
              Sherlookez
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Layout>
  );
}
