import React, { useContext } from 'react'
import { Form } from 'react-bootstrap';
import AnnonceContext from '../../../../contexts/AnnonceContext';

export default function Textbox({name, isSecret}) {
  const {  setFormAttributes } = useContext(AnnonceContext);
  return (
  
    <div>

     <Form.Control type="text" placeholder={name} 
      onChange={(e) =>
        setFormAttributes((formAttributes) => [
          ...formAttributes.filter((att) => att.attribute !== name),
          {
            attribute: name,
            attributeValue: e.target.value,
            isSecret: isSecret,
          },
        ])
      }
     />
    </div>
  );
}
