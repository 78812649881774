import { TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import AnnonceContext from "../../../contexts/AnnonceContext";
import Upload from "./Forms/upload";

export default function Step3() {
  const { formAnnonce, setFormAnnonce } = useContext(AnnonceContext);

  return (
    <>
      <Typography variant="body1">
        Une photo permet d'augmenter x 3 l'efficacité de votre annonce
      </Typography>

      <Upload />

      <Typography variant="body1">
        Décrivez ici les circonstances de la perte et/ou tout autre détail
        important pour vous, afin d’apporter un maximum de précisions à votre
        annonce.
      </Typography>
      <Typography variant="body1">
        Ex. Je pense avoir perdu mon objet en sortant du restaurant, place de la
        mairie à Rennes.
      </Typography>

      <TextField
        fullWidth
        id="outlined-multiline-flexible"
        label="Détails"
        multiline
        maxRows={4}
        value={formAnnonce.details}
        onChange={(e) =>
          setFormAnnonce({ ...formAnnonce, details: e.target.value })
        }
      />
    </>
  );
}
