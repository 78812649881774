import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";

export default function FilterSelectorAttributes({
  label,
  uiLabel,
  ui,
  dataFilters,
  filteredAttributes,
  setFilteredAttributes,
}) {
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedValueText, setSelectedValueText] = useState("");

//  const [ssSelectValue, setSsSelectValue] = useState("");
//   const [sousSelect, setSousSelect] = useState(""); 



  
  // const handleChangeSs = (event) => {

  //   setSsSelectValue(event.target.value.name);
  //   setFilteredAttributes((filteredAttributes) => [
  //     ...filteredAttributes.filter((fatt) => fatt.attribute !== event.target.value.name),
  //     {
  //       attribute: event.target.value,
  //       attributeValue: event.target.value.name
  //     },
  //   ]);
    
  // }
  const handleChange = (event) => {
   
    setSelectedValue(event.target.value);

    // if (event.target.value.attributes && event.target.value.attributes.length > 0) {
  
    //   setSousSelect  (
    //      <>
    //      <InputLabel id={"select" + event.target.value.attributes.name+ "-label"}>{event.target.value.name}</InputLabel>

    //     <Select
    //       sx={{
    //         backgroundColor: "#fff",
          
    //       }}
    //       labelId={"select" + event.target.value.name+ "-label"}
    //       id={"select" + event.target.value.name}
    //       value={setSsSelectValue}
    //       label={event.target.value}
    //       onChange={handleChangeSs}
    //       placeholder="selectionnez"
    //     >
    //       <MenuItem key={-1} value={-1}>
    //         Tout voir
    //       </MenuItem>
    //       {event.target.value.attributes.map((attribute, key) => (
    //         <MenuItem key={key} value={attribute}>
    //           {attribute.name}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //      </>
 
    //    )} else {
    //     setSousSelect("")
    //    }
  
    setFilteredAttributes((filteredAttributes) => [
      ...filteredAttributes.filter((fatt) => fatt.attribute !== label),
      {
        attribute: label,
        attributeValue: event.target.value
      },
    ]);
  };

  const handleChangetext = (event) => {
    setSelectedValueText(event.target.value);
    setFilteredAttributes((filteredAttributes) => [
      ...filteredAttributes.filter((fatt) => fatt.attribute !== label),
      {
        attribute: label,
        attributeValue: event.target.value,
      },
    ]);

    }


  return (
     <Box sx={{ minWidth: 160 }}>
      <FormControl fullWidth>

      

      {ui !== "textbox" && 
      <>
        <InputLabel id={"select" + label+ "-label"}>{uiLabel}</InputLabel>
        <Select
          sx={{
            backgroundColor: "#fff",
          
          }}
          labelId={"select" + label+ "-label"}
          id={"select" + label}
          value={selectedValue}
          label={uiLabel}
          onChange={handleChange}
          placeholder="selectionnez "
        >
          <MenuItem key={-1} value={-1}>
            Tout voir
          </MenuItem>
          {dataFilters.map((attribute, key) => (
            <MenuItem key={key} value={attribute.name}>
              {attribute.name}
            </MenuItem>
          ))}
        </Select>
        </>
} 
    {ui === "textbox" && 
        <TextField id={label}  label={uiLabel} value={selectedValueText} variant="outlined" onChange={handleChangetext}/>
    
    
    }
      </FormControl>
    </Box>
  );
}
