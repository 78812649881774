import React, { useContext, useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import authAPI from "../../services/authAPI";
import NotificationsPopover from "./NotificationsPopover";
const DrawerComp = ({ color = "#333" } = {}) => {
  const [open, setOpen] = useState(false);
  const { isAuthenticated, setIsAuthenticated, setUser } = useContext(
    AuthContext
  );
  const navigate = useNavigate();
  const handleLogout = () => {
    authAPI.logout();
    setIsAuthenticated(false);
    setUser([]);
    navigate("/");
  };

  return (
    <>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)} p={5}>
        <Grid display="flex" sx={{ padding: "20px" }}>
          <img src="images/site/2.svg" width="100px" />
        </Grid>
        <List>
          <ListItemButton
            onClick={() => setOpen(false)}
            sx={{ py: 0, minHeight: 32 }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <ListItemText
                primary="Objets trouvés Vieilles Charrues"
                primaryTypographyProps={{ fontSize: 15, fontWeight: "medium" }}
              ></ListItemText>
            </Link>
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            sx={{ py: 0, minHeight: 32 }}
          >
            <Link to="/publier" style={{ textDecoration: "none" }}>
              <ListItemText
                primary="Publier une Annonce"
                primaryTypographyProps={{ fontSize: 15 }}
                secondary="Vous avez trouvé ou perdu un objet ?"
                secondaryTypographyProps={{
                  fontSize: 12,
                }}
              ></ListItemText>
            </Link>
          </ListItemButton>
          <Divider />

          <ListItemButton onClick={() => setOpen(false)}>
            <Link to="/objets-trouves" style={{ textDecoration: "none" }}>
              <ListItemIcon>
                <ListItemText
                  primary="Objets Trouvés"
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: "medium",
                  }}
                ></ListItemText>
              </ListItemIcon>
            </Link>
          </ListItemButton>

          <ListItemButton onClick={() => setOpen(false)}>
            <Link to="/objets-perdus" style={{ textDecoration: "none" }}>
              <ListItemIcon>
                <ListItemText
                  primary="Objets Perdus"
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: "medium",
                  }}
                ></ListItemText>
              </ListItemIcon>
            </Link>
          </ListItemButton>
          <Divider />
          {!isAuthenticated && (
            <ListItemButton onClick={() => setOpen(false)}>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <ListItemIcon>
                  <ListItemText
                    primary="Connexion"
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                    }}
                    secondary="Connectez-vous ou créez un compte pour publier"
                    secondaryTypographyProps={{
                      fontSize: 12,
                    }}
                  ></ListItemText>
                </ListItemIcon>
              </Link>
            </ListItemButton>
          )}

          {isAuthenticated && (
            <>
              <ListItemButton onClick={() => setOpen(false)}>
                <Link to="/mon-compte" style={{ textDecoration: "none" }}>
                  <ListItemIcon>
                    <ListItemText
                      primary="Connexion"
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "medium",
                      }}
                      secondary="vos annonces et votre messagerie"
                      secondaryTypographyProps={{
                        fontSize: 12,
                      }}
                    ></ListItemText>
                  </ListItemIcon>
                </Link>
              </ListItemButton>
              <ListItemButton onClick={handleLogout}>
                <ListItemText>Déconnexion</ListItemText>
              </ListItemButton>
            </>
          )}
        </List>
      </Drawer>
      {isAuthenticated && (
        <>
          <Box
            sx={{
              marginLeft: "3px",
              position: "absolute",
              right: "50px",
              color: { color },
            }}
          >
            <NotificationsPopover />
          </Box>
        </>
      )}
      <IconButton
        sx={{
          marginLeft: "3px",
          position: "absolute",
          right: "10px",
          color: { color },
        }}
      >
        <MenuRoundedIcon onClick={() => setOpen(true)} />
      </IconButton>
    </>
  );
};

export default DrawerComp;
