import React from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Container, Typography, Box } from "@mui/material";
// hooks

// components

import { LoginForm } from "./auth/login";
import useResponsive from "../../hooks/useResponsive";
import { Link } from "react-router-dom";
import Logo from "../../Components/layout/Logo";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "top",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  return (
    <RootStyle>
      <HeaderStyle></HeaderStyle>

      {mdUp && (
        <SectionStyle
          sx={{
            backgroundImage: "url(/images/site/right-teddy-sidebar.png)",
            margin: 0,
          }}
        >
          <Box sx={{ p: 5, color: "#fff" }}>
            <Logo width="400" color="white" />

            <Typography variant="h3" mt={5} textAlign="center">
              Bienvenue !
            </Typography>
          </Box>
        </SectionStyle>
      )}

      <Container maxWidth="sm">
        <ContentStyle>
          <LoginForm />

          <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
            vous n'avez pas de compte chez sherlook ?{" "}
            <Link variant="subtitle2" to="/inscription" component={Link}>
              inscrivez-vous !
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
