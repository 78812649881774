import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../Components/layout/Logo";

import "./Homepage.css";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Autocomplete,
  Stack,
  useTheme,
  useMediaQuery,
  Container,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import Navbar from "../../Components/layout/Navbar";
import axios from "axios";
import GoogleAddress from "../../Components/GoogleAddress";
import DrawerComp from "../../Components/layout/DrawerComp";

const CustomButton = styled(Button)`
  color: #ffffff;
  background-color: #4abdf0;
  height: 50px;
  :hover {
    color: #ffffff;
    background-color: #4abdee;
  }
`;

const RightButton = styled(Button)`
  color: #ffffff;
  border: 2px solid #fff;
  border-radius: 10px;
  width: 50%;
  padding: 20px;
  margin-bottom: 20px;
  text-transform: none;
`;

const CustomTextField = styled(TextField)`
  margin-bottom: 20px;
`;

function Hero() {
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_PUBLIC_API +
      "/categories?parent=1&groups[]=onlyParent&order[sort]=asc";

    axios.get(apiUrl).then((resp) => {
      setCategories(resp.data);
    });
  }, []);

  const handleNavigate = (url) => {
    window.localStorage.setItem("returnLinkMessage", url);
  };

  return (
    <>
      {isMatchSm ? (
        <>
          <Container className="heroMobile">
            <Grid container justifyContent="center">
              <Grid>
                <DrawerComp color={"white"} />
                <Logo width="200px" color="white" />
              </Grid>
              <Grid>
                <Typography
                  textAlign="center"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  Je recherche mon objet perdu en direct
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Box sx={{ minWidth: 300 }} mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="select-bar-label">Objets</InputLabel>
                    <Select
                      sx={{
                        backgroundColor: "#fff",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      label="Objets"
                      onChange={handleChangeCategory}
                      placeholder="Selectionnez un Objet"
                    >
                      {/* <MenuItem key={-1} value={0}>
            Tout voir
          </MenuItem> */}
                      {categories.map((filter) => (
                        <MenuItem key={filter.id} value={filter.id}>
                          {filter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* <Autocomplete
                  disablePortal
                  id="recherche-objet"
                  options={categories}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 300, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Objets"
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  )}
                /> */}
                <Grid item sm={12}>
                  <GoogleAddress />
                </Grid>

                <Grid item sm={12}>
                  <CustomButton
                    sx={{ width: 300, marginTop: 2, marginBottom: 2 }}
                    variant="contained"
                    color="primary"
                  >
                    Sherlooker !
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              Container
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              height="50vh"
            >
              <Grid m={2}>
                <Typography
                  gutterBottom
                  paragraph
                  sx={{
                    color: "#333333",
                    padding: "5px",
                    marginY: "10px",
                  }}
                >
                  JE PUBLIE MON ANNONCE EN DIRECT
                </Typography>
              </Grid>

              <Grid m={2}>
                <Link className="homeMobileButton" to="/publier-trouve">
                  <Button
                    onClick={() => {
                      handleNavigate("/publier-trouve");
                    }}
                  >
                    <Stack>
                      <Typography variant="h5">J'ai trouvé</Typography>
                      <Typography variant="caption">
                        un objet ou un animal
                      </Typography>
                    </Stack>
                  </Button>
                </Link>
              </Grid>

              <Grid>
                <Link className="homeMobileButton" to="/publier-perdu">
                  <Button
                    onClick={() => {
                      handleNavigate("/publier-perdu");
                    }}
                  >
                    <Stack>
                      <Typography variant="h5">J'ai perdu</Typography>
                      <Typography variant="caption">
                        un objet ou un animal
                      </Typography>
                    </Stack>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        // fin mobile

        <Container
          maxWidth="false"
          className="hero"
          sx={{
            backgroundColor: "#ccc",
            height: "100vh",
          }}
        >
          <Grid container>
            <Grid container>
              <Grid item md={6} xs={12} sx={{ padding: 5 }}>
                {isMatchSm ? (
                  <>
                    <Logo width="280px" color="white" />
                  </>
                ) : (
                  <>
                    <Logo />
                  </>
                )}
              </Grid>

              <Grid item md={6} sm={12}>
                {isMatchSm ? (
                  <DrawerComp color={"white"} />
                ) : (
                  <Navbar linkColor="#fff" />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Container
                maxWidth="sm"
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  marginTop: "100px",
                }}
              >
                <Box sx={{ minWidth: "100px" }}>
                  <Typography gutterBottom paragraph>
                    Je recherche mon objet perdu en direct
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="recherche-objet"
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Objets"
                        sx={{
                          backgroundColor: "#fff",
                        }}
                      />
                    )}
                  />
                  <GoogleAddress />

                  <CustomButton
                    sx={{ width: 300, marginTop: 2 }}
                    variant="contained"
                    color="primary"
                  >
                    Sherlooker !
                  </CustomButton>
                </Box>
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container
                maxWidth="sm"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "100px",
                }}
              >
                <Typography
                  gutterBottom
                  paragraph
                  sx={{
                    color: "#fff",
                  }}
                >
                  JE PUBLIE MON ANNONCE EN DIRECT
                </Typography>
                <Link className="homeLink" to="/publier-trouve">
                  <RightButton
                    onClick={() => {
                      handleNavigate("/publier-trouve");
                    }}
                  >
                    <Stack>
                      <Typography variant="h5">J'ai trouvé</Typography>
                      <Typography variant="caption">
                        un objet ou un animal
                      </Typography>
                    </Stack>
                  </RightButton>
                </Link>
                <Link className="homeLink" to="/publier-perdu">
                  <RightButton
                    onClick={() => {
                      handleNavigate("/publier-perdu");
                    }}
                  >
                    <Stack>
                      <Typography variant="h5">J'ai perdu</Typography>
                      <Typography variant="caption">
                        un objet ou un animal
                      </Typography>
                    </Stack>
                  </RightButton>
                </Link>
              </Container>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

export default Hero;
