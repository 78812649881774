import React from "react";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import { Link } from "react-router-dom";

export default function DashboardBox({ children, link }) {
  return (
    <Box
      sx={{
        display: "flex",

        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
        },
      }}
    >
      <Link to={link} style={{ textDecoration: "none" }}>
        <Paper
          elevation={3}
          sx={{
            width: 250,
            height: 150,
            borderRadius: "10px",
            padding: "10px",
            backgroundColor: "#4ABDF0",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            fontSize: "30px",
            justifyItems: "space-around"
            
          
          }}
        >
          {children}
        </Paper>
      </Link>
    </Box>
  );
}
