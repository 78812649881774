import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CardMedia,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { set } from "date-fns/esm";
import { is } from "date-fns/locale";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../Components/layout/Layout";
import AuthContext from "../../contexts/AuthContext";
import authAPI from "../../services/authAPI";

export default function Message() {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, user } = useContext(AuthContext);
  const { annonce } = useParams() || null;
  const [foundAds, setFoundAds] = useState(null);
  const [cover, setCover] = useState("");
  const [owner, setOwner] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // recuperation de l'annonce
  const [conversationExist, setConversationExist] = useState(false);
  const [isSend, setIsSend] = useState(false);


  setIsAuthenticated(authAPI.isAuthenticated);

  const fetchFoundAds = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/annonces/${annonce}`)
        .then((response) => response.data);
      setFoundAds(data);

      const { conversations } = data;
    
      setCover(
        data.category.isImageFrontHidden
          ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${data.category.defaultImage}`
          : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${data.filePath}`
      );

      setConversation({
        annonce: `api/annonces/${data.id}`,
        messages: [
          {
            content: "",
          },
        ],
      });

      if (isAuthenticated && null !== user) {

        conversations.forEach((element) => {
          if (element.author.id === user.id) {
            setConversationExist(true);
          }
        });
      }

      setOwner(data.user !== null ? data.user.firstname : data.customer.email);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchFoundAds();
  }, []);

  const handleNewConversation = async () => {
    setIsLoading(true);
    if (isAuthenticated) {
      // persist une nouvelle conversation sur l'annonce en cours
      try {
        await axios.post(
          process.env.REACT_APP_PUBLIC_API + "/conversations",
          conversation
        );
        setIsLoading(false);
        setIsSend(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <Layout>
      {null !== foundAds && (
        <>
          {conversationExist ? (
            <>
              <Typography
                variant="h6"
                sx={{
                  p: 1,
                }}
              >
                Vous avez déja contacté l'annonceur à propos de cette objet
              </Typography>
              <Button
                sx={{
                  backgroundColor: "#4ABDF0",
                  width: "100%",
                }}
                variant="contained"
                onClick={() => navigate("/messages")}
              >
                <Typography>Voir votre messagerie</Typography>
              </Button>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                {!isSend ? (
                  <Paper
                    elevation={2}
                    sx={{
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        p: 1,
                      }}
                    >
                      Envoyer un message à
                    </Typography>
                    <Typography
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      {owner}
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      label="Votre message"
                      multiline
                      rows={4}
                      onChange={(e) =>
                        setConversation({
                          ...conversation,
                          messages: [
                            {
                              content: e.target.value,
                            },
                          ],
                        })
                      }
                    />

                    <LoadingButton
                      sx={{
                        mt: 2,
                        backgroundColor: "#4ABDF0",
                      }}
                      loading={isLoading}
                      variant="contained"
                      onClick={handleNewConversation}
                    >
                      Envoyer le message
                    </LoadingButton>
                  </Paper>
                ) : (
                  <>
                    <Typography variant="h5">
                      votre message a été envoyé, vous pouvez consulter la
                      conversation dans votre messagerie!
                    </Typography>
                    <Link to="/messages">
                      <Button>Messagerie</Button>
                    </Link>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5">Objet trouvé</Typography>
                <Paper elevation={2}>
                  <CardMedia
                    component="img"
                    width="240"
                    image={cover}
                    alt={foundAds.category.name}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                    }}
                  >
                    <Typography variant="h5">
                      {" "}
                      {foundAds.category.name}{" "}
                    </Typography>
                    {/* <Chip label={foundAds.id} color="primary" /> */}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Layout>
  );
}
