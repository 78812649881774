import React, { useState, useEffect, useRef, useContext } from "react";
import "./Chat.css";
import {
  Avatar,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Paper,
  CardMedia,
  Container,
  useTheme,
  useMediaQuery,
  Drawer,
  IconButton,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Layout from "../layout/Layout";
import axios from "axios";
import AuthContext from "../../contexts/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import ActionDialogBox from "../ActionDialogBox";
import { useNavigate } from "react-router-dom";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import authAPI from "../../services/authAPI";
export default function Chat({ annonce }) {
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [conversationKey, setConversationKey] = useState(false);
  const [messAuth, setMessAuth] = useState();
  const [dialogAction, setDialogAction] = useState(null);
  const [deleteConversationId, setDeleteConversationId] = useState(null);
  const [isSending, setIsSending] = useState(false);

  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchMd = useMediaQuery(theme.breakpoints.down("lg"));
  const { user, setUser, isAuthenticated, setIsAuthenticated } = useContext(
    AuthContext
  );
  const navigate = useNavigate();
  setIsAuthenticated(authAPI.isAuthenticated);

  if (!isAuthenticated) {
    navigate("/login");
  }

  useEffect(() => {
    fetchConversations();
  }, [message]);
  useEffect(() => {
    const id = setInterval(fetchConversations, 30000);
    return () => clearInterval(id);
  }, [conversationKey]);

  const [open, setOpen] = useState(false);
  const [messageDialog, setMessageDialog] = useState("");
  const [openConv, setOpenConv] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // recup des conversations
  const fetchConversations = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/conversations`)
        .then((response) => response.data);

      setConversations(data);
      setCurrentConversation(conversationKey ? data[conversationKey] : data[0]);
      setCurrentMessages(
        conversationKey
          ? data[conversationKey].messages.sort((x, y) => x.id - y.id)
          : data[0].messages.sort((x, y) => x.id - y.id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  // recuperations des conversations de l'user
  const handleConversation = (conversation, index) => {
    setCurrentConversation(conversation);
    setCurrentMessages(conversation.messages);
    setConversationKey(index);
  };

  const handleDelete = () => {
    handleClose();
  };

  const handleAlert = (action, conversationId) => {
    switch (action) {
      case "deleteConversation":
        setMessageDialog("Voulez vous Vraiment supprimer cette conversation ?");
        setDeleteConversationId(conversationId);
        setOpen(true);
        break;

      default:
        return;
    }
  };

  const handleMessage = async () => {
    if (message !== "") {
      setIsSending(true);
      const data = {
        conversation: `api/conversations/${currentConversation.id}`,
        content: message,
      };

      try {
        await axios.post(process.env.REACT_APP_PUBLIC_API + "/messages", data);
        setMessage("");
        setIsSending(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Layout>
      <Container>
        {conversations.length > 0 ? (
          <Grid
            container
            component={Paper}
            sx={{
              width: "100%",
              height: "80vh",
            }}
          >
            {isMatchSm ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setOpenConv(!openConv)}
                  startIcon={<QuestionAnswerIcon />}
                ></Button>
                <Drawer
                  anchor="left"
                  open={openConv}
                  onClick={() => setOpenConv(!openConv)}
                >
                  <Grid
                    item
                    md={4}
                    sx={{
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    <List>
                      <ListItem button key="">
                        <ListItemIcon>
                          <Avatar />
                        </ListItemIcon>
                        <ListItemText primary="vous"></ListItemText>
                      </ListItem>
                    </List>
                    <Divider />
                    <Grid item sx={{ padding: "5px" }}></Grid>
                    <Divider />
                    <List>
                      {/* bouclage des annonces    setCurrentConversations(data)  */}

                      {conversations.length > 0 &&
                        conversations.map((conversation, index) => (
                          <ListItem
                            sx={{
                              background:
                                currentConversation !== null &&
                                currentConversation === conversation
                                  ? "#ccc"
                                  : "#fff",
                            }}
                            button
                            key={index}
                            onClick={() =>
                              handleConversation(conversation, index)
                            }
                          >
                            <ListItemIcon>
                              <Avatar
                                alt=""
                                src={
                                  conversation.annonce.category
                                    .isImageFrontHidden
                                    ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${conversation.annonce.category.defaultImage}`
                                    : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`
                                }
                              />
                            </ListItemIcon>

                            <ListItemText
                              primary={conversation.annonce.category.name}
                              // secondary={conversation.annonce.customer.email}
                            ></ListItemText>
                            <ListItemIcon sx={{ marginLeft: "20px" }}>
                              <DeleteIcon
                                onClick={() =>
                                  handleAlert(
                                    "deleteConversation",
                                    conversation.id
                                  )
                                }
                              />
                            </ListItemIcon>
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                </Drawer>
              </>
            ) : (
              <Grid
                item
                md={4}
                sx={{
                  borderRight: "1px solid #e0e0e0",
                }}
              >
                <List>
                  <ListItem button key="">
                    <ListItemIcon>
                      <Avatar />
                    </ListItemIcon>
                    <ListItemText primary="vous"></ListItemText>
                  </ListItem>
                </List>
                <Divider />
                <Grid item sx={{ padding: "5px" }}></Grid>
                <Divider />
                <List>
                  {/* bouclage des annonces    setCurrentConversations(data)  */}

                  {conversations.length > 0 &&
                    conversations.map((conversation, index) => (
                      <ListItem
                        sx={{
                          background:
                            currentConversation !== null &&
                            currentConversation === conversation
                              ? "#ccc"
                              : "#fff",
                        }}
                        button
                        key={index}
                        onClick={() => handleConversation(conversation, index)}
                      >
                        <ListItemIcon>
                          <Avatar
                            alt=""
                            src={
                              conversation.annonce.category.isImageFrontHidden
                                ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${conversation.annonce.category.defaultImage}`
                                : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`
                            }
                          />
                        </ListItemIcon>

                        <ListItemText
                          primary={conversation.annonce.category.name}
                          // secondary={conversation.annonce.customer.email}
                        ></ListItemText>
                        <ListItemIcon sx={{ marginLeft: "20px" }}>
                          <DeleteIcon
                            onClick={() =>
                              handleAlert("deleteConversation", conversation.id)
                            }
                          />
                        </ListItemIcon>
                      </ListItem>
                    ))}
                </List>
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              {/* boucle messages  */}

              <List sx={{ height: "70vh", overflowY: "auto" }}>
                {currentConversation.length > 0 && (
                  <ListItem key="0">
                    <Grid container>
                      <Grid item xs={12}>
                        <ListItemText align="right" secondary=""></ListItemText>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}

                {currentMessages.length > 0 &&
                  currentMessages.map((message) => {
                    const messAuthId =
                      null !== message.customerAuthor
                        ? message.customerAuthor.id
                        : message.author.id;
                    const content = message.content;
                    return (
                      <ListItem key={message.id}>
                        <Grid container>
                          <Grid item xs={12}>
                            {message.isRead ? (
                              <>
                                <ListItemText
                                  sx={{
                                    backgroundColor: "green",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.location.replace(content);
                                  }}
                                  primary={content}
                                ></ListItemText>
                              </>
                            ) : (
                              <>
                                <ListItemText
                                  className={
                                    messAuthId === user.id ? "right" : "left "
                                  }
                                  align={
                                    messAuthId === user.id ? "right" : "left "
                                  }
                                  primary={message.content}
                                ></ListItemText>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <ListItemText
                              align={messAuthId === user.id ? "right" : "left "}
                              secondary={message.createdAt}
                            ></ListItemText>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                {/* <div ref={messagesEndRef}> </div> */}
              </List>
              <Divider />
              <Grid container style={{ padding: "20px" }}>
                <Grid item xs={11}>
                  <TextField
                    id="outlined-basic-email"
                    label="Votre message ..."
                    value={message}
                    fullWidth
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={1} align="right">
                  <Fab color="primary" aria-label="add" disabled={isSending}>
                    <SendIcon onClick={handleMessage} />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography>Votre messagerie est vide</Typography>
          </>
        )}
      </Container>

      <ActionDialogBox
        open={open}
        handleClose={handleClose}
        handleAction={handleDelete}
        message={messageDialog}
        elementActionId={deleteConversationId}
        actionButtonText="Supprimer la conversation"
      />
    </Layout>
  );
}
