import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
// material
import {
  AppBar,
  Button,
  Container,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
// components

//import AnnonceSelectorBar from 'src/components/FormAnnonceList/AnnonceSelectorBar';

import CloseIcon from "@mui/icons-material/Close";
import AnnonceDetails from "./AnnonceDetails";
import AuthContext from "../../contexts/AuthContext";
import Layout from "../../Components/layout/Layout";
import ProductList from "./ProductList";
import AnnonceSelector from "../../Components/Forms/AnnonceSelector";

// ----------------------------------------------------------------------
const initialState = {
  selectedCategory: null,
  setSelectedCategory: () => undefined,
  filteredAttributes: null,
  setFilteredAttributes: () => undefined,
  date: null,
  setDate: () => undefined,
};
export const FoundContext = createContext(initialState);

export default function EcommerceShop() {
  const { user, setUser } = useContext(AuthContext);
  const [selectedAnnonce, setSelectedAnnonce] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [foundAds, setFoundAds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [date, setDate] = useState(false);

  useEffect(() => {
    fetchFoundAds();
  }, [user]);

  const initialState = {
    selectedCategory: null,
    setSelectedCategory: () => undefined,
    filteredAttributes: null,
    setFilteredAttributes: () => undefined,
  };

  const handleClickOpenDialog = (data) => {
    setSelectedAnnonce(data);

    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchFoundAds = async () => {
    try {
      const data = await axios
        .get(process.env.REACT_APP_PUBLIC_API + `/users/${user.id}/annonces`)
        .then((response) => response.data);
      setFoundAds(data);

    } catch (error) {
      console.error(error);
    }
  };

  const filteredAnnonces = foundAds.filter((a) =>
    selectedCategory ? a.category.id === selectedCategory : a.category.id > 0
  );

  const filteredAnnonceByDate = filteredAnnonces.filter((a) =>
    date
      ? date.setHours(0, 0, 0, 0) === new Date(a.date).setHours(0, 0, 0, 0)
      : new Date(a.date) > 0
  );

  const filteredAnnoncesByAttributes = filteredAnnonceByDate.filter((a) => {
    return filteredAttributes.every((attribute) => {
      return a.annonceAttributes.some((a) =>
        attribute.attributeValue !== -1
          ? a.attribute === attribute.attribute &&
            a.attributeValue === attribute.attributeValue
          : a.attribute != null
      );
    });
  });

  return (
    <Layout>
      <FoundContext.Provider
        value={{
          selectedCategory,
          setSelectedCategory,
          filteredAttributes,
          setFilteredAttributes,
          date,
          setDate,
        }}
      >
        <Container>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Mes Annonces
          </Typography>

          {/* <AnnonceSelector /> */}

          <ProductList
            myAds={filteredAnnoncesByAttributes}
            handleClickOpenDialog={handleClickOpenDialog}
          />
        </Container>

        {/* Annonce dialogue */}
        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleCloseDialog}
          // TransitionComponent={Transition}
        >
          <AppBar
            elevation={1}
            sx={{
              position: "relative",
              backgroundColor: "transparent",
              height: "60px",
            }}
          >
            <Toolbar sx={{ height: "20px" }}>
              <IconButton
                edge="start"
                onClick={handleCloseDialog}
                aria-label="close"
                sx={{
                  color: "#1B9098",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <AnnonceDetails selectedAnnonce={selectedAnnonce} />
        </Dialog>
      </FoundContext.Provider>
    </Layout>
  );
}
