import {
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FoundContext } from "../../Pages/found/Found";
import FilterSelector from "./FilterSelector";
import axios from "axios";
import FilterSelectorAttributes from "./FilterSelectorAttributes";
import { Box } from "@mui/system";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
export default function AnnonceSelector({ customerId }) {
  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    selectedCategory,
    setSelectedCategory,
    filteredAttributes,
    setFilteredAttributes,
    date,
    setDate,
  } = useContext(FoundContext);
  const [categories, setCategories] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());

  const handleChangeDate = (newDate) => {
    setDate(newDate);
    setDateSelected(newDate);
  };

  const handleResetDate = () => {
    setDate(false);
  };

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_PUBLIC_API + "/categories?parent=1&order[sort]=asc";

    axios.get(apiUrl).then((resp) => {
      setCategories(resp.data);
    });
  }, []);

  const day = new Date();
  const [alignment, setAlignment] = useState("web");
  const filteredCategory = selectedCategory
    ? categories.filter((a) => a.id === selectedCategory)
    : null;
  const handleChangeDay = (event, newAlignment) => {
    if (event.target.value === "reset") {
      handleResetDate();
      setAlignment(newAlignment);
    } else {
      day.setDate(day.getDate() - event.target.value);
      setAlignment(newAlignment);
      setDate(day);
    }
  };

  return (
    <Box
    // sx={
    //   customerId
    //     ? {
    //         display: "flex",
    //         alignContent: "center",
    //         alignItems: "center",
    //         height: "400px",
    //         backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL +
    //           "/images/customers/visuels/customer" +
    //           customerId +
    //           ".png"})`,
    //         backgroundSize: "cover",
    //         backgroundRepeat: "no-repeat",
    //         padding: "20px",
    //       }
    //     : null
    // }
    >
      <Box
        sx={{
          ...(!isMatchSm && {
            display: "inline-flex",
            justifyContent: "start",
            height: "auto",

            padding: "10px",
            borderRadius: "5px",
            // width: "100%",
          }),
        }}
      >
        <Stack spacing={2}>
          <Stack direction={isMatchSm ? "column" : "row"} spacing={2}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={frLocale}
            >
              {isMatchSm ? (
                <MobileDatePicker
                  label="Quand ?"
                  inputFormat="dd/MM/yyyy"
                  value={dateSelected}
                  onChange={(newDate) => {
                    handleChangeDate(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              ) : (
                <DesktopDatePicker
                  label="Quand ?"
                  inputFormat="dd/MM/yyyy"
                  value={dateSelected}
                  onChange={(newDate) => {
                    handleChangeDate(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </LocalizationProvider>

            <ToggleButtonGroup
              size="small"
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChangeDay}
            >
              <ToggleButton
                sx={{
                  fontSize: isMatchSm ? "10px" : "14px",
                }}
                variant="contained"
                value={0}
              >
                Aujourd'hui
              </ToggleButton>
              <ToggleButton
                sx={{
                  fontSize: isMatchSm ? "10px" : "14px",
                }}
                value={1}
              >
                Hier
              </ToggleButton>
              <ToggleButton
                sx={{
                  fontSize: isMatchSm ? "10px" : "14px",
                }}
                value={2}
              >
                Avant-hier
              </ToggleButton>
              <ToggleButton
                sx={{
                  fontSize: isMatchSm ? "10px" : "14px",
                }}
                value={3}
              >
                il y' a 3 jours
              </ToggleButton>
              <ToggleButton
                sx={{
                  fontSize: isMatchSm ? "10px" : "14px",
                }}
                value="reset"
              >
                toutes les dates
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Stack direction={isMatchSm ? "column" : "row"} spacing={2}>
            <FilterSelector
              label="Quoi ?"
              selectedValue={selectedCategory}
              setSelectedValue={setSelectedCategory}
              dataFilters={categories}
              setFilteredAttributes={setFilteredAttributes}
              filteredAttributes={filteredAttributes}
            />

            {filteredCategory &&
              filteredCategory.map((data, key) => {
                return (
                  <Fragment key={key}>
                    {data.attribute.map((attribute, k) => {
                      return attribute.isSecret !== true ? (
                        <FilterSelectorAttributes
                          key={k}
                          filteredAttributes={filteredAttributes}
                          setFilteredAttributes={setFilteredAttributes}
                          label={attribute.name}
                          uiLabel={attribute.uiLabel}
                          dataFilters={attribute.attributes}
                          ui={attribute.ui}
                        />
                      ) : (
                        <></>
                      );
                    })}
                  </Fragment>
                );
              })}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
