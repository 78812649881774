import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Logo({
  width = 483,
  height = 136,
  customerId,
  color = "black",
}) {
  const navigate = useNavigate();
  const urlLogo = `/images/site/logo-${color}.svg`;
  const handleAccueil = () => {
    navigate("/");
  };
  let logo;
  let baseLine;
  if (customerId) {
    logo = (
      <img
        src={`/images/site/${customerId}.svg`}
        width={width}
        height={height}
        alt="logo"
      />
    );

    baseLine = (
      <Typography variant="h5" sx={{}}>
        Un objet perdu pendant le festival ?
      </Typography>
    );
  } else {
    logo = <img src={urlLogo} width={width} height={height} alt="logo" />;
    baseLine = "";
  }

  return (
    <Box className="logo" onClick={handleAccueil} sx={{ cursor: "pointer" }}>
      {logo}
      {baseLine}
    </Box>
  );
}
