import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function FilterSelector({
  label,
  dataFilters,
  selectedValue = 0,
  setSelectedValue,
  setFilteredAttributes,
}) {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setFilteredAttributes([]);
  };

  return (
    <Box sx={{ minWidth: 160, marginRight: "10px" }}> 
      <FormControl fullWidth>
        <InputLabel id="select-bar-label">{label}</InputLabel>
        <Select 
          sx={{
            backgroundColor: "#fff",
          
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label={label}
          onChange={handleChange}
          placeholder="selectionnez "
        >
          {/* <MenuItem key={-1} value={0}>
            Tout voir
          </MenuItem> */}
          {dataFilters.map((filter) => (
            <MenuItem key={filter.id} value={filter.id}>
              {filter.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
