import {
  Avatar,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Paper,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Container,
  Drawer,
  BottomNavigation,
} from "@mui/material";

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import "./Chat.css";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
export default function ChatAnnonce({ annonce }) {
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [message, setMessage] = useState("");
  const { user } = useContext(AuthContext);
  const [isSend, setIsSend] = useState(false);
  const [conversationKey, setConversationKey] = useState(false);

  const {
    id,
    title,
    filePath,
    status,
    annonceAttributes,
    category,
    stockage,
    date,
  } = annonce;
  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchMd = useMediaQuery(theme.breakpoints.down("lg"));

  const [openConv, setOpenConv] = useState(false);

  const ShButton = styled(Button)`
    background-color: #1b9098;
    color: #fff;
    width: 80%;
    text-transform: uppercase;
    padding: 6px 12px;
    &:hover {
      background-color: #85c4c8;
    }
  `;
  useEffect(() => {
    fetchConversations();
  }, [message, isSend]);

  useEffect(() => {
    const id = setInterval(fetchConversations, 10000);
    return () => clearInterval(id);
  }, [conversationKey]);

  const fetchConversations = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/conversations?annonce=${id}`)
        .then((response) => response.data);

      setConversations(data);

      setCurrentConversation(conversationKey ? data[conversationKey] : data[0]);

      if (conversationKey.messages) {
        setCurrentMessages(
          conversationKey
            ? data[conversationKey].messages.sort((x, y) => x.id - y.id)
            : data[0].messages.sort((x, y) => x.id - y.id)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleConversation = (conversation, index) => {
    setCurrentConversation(conversation);
    setCurrentMessages(conversation.messages);
    setConversationKey(index);
  };

  const handleMessage = async () => {
    if (message !== "") {
      const data = {
        conversation: `api/conversations/${currentConversation.id}`,
        content: message,
      };

      try {
        await axios.post(process.env.REACT_APP_PUBLIC_API + "/messages", data);
        setMessage("");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const blockMessage = (
    <List sx={{ height: "90vh", overflowY: "auto" }}>
      <ListItem key="0">
        <Grid container>
          <Grid item xs={12}>
            <ListItemText align="right" secondary=""></ListItemText>
          </Grid>
        </Grid>
      </ListItem>

      {/* debut des message */}

      {currentMessages.length > 0 &&
        currentMessages.map((message) => (
          <ListItem key={message.id}>
            <Grid container>
              <Grid item xs={12}>
                <ListItemText
                  className={message.author.id === user.id ? "right" : "left "}
                  align={message.author.id === user.id ? "right" : "left "}
                  primary={message.content}
                ></ListItemText>
              </Grid>
              <Grid item xs={12}>
                <ListItemText
                  align={message.author.id === user.id ? "right" : "left "}
                  secondary={message.createdAt}
                ></ListItemText>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      {/* <div ref={messagesEndRef}></div>  */}

      {/* fin des message */}
    </List>
  );

  const blockSender = (
    <Grid container p={1} display="flex">
      <Grid item xs={9}>
        <TextField
          sx={{
            backgroundColor: "#fff",
          }}
          autoComplete="off"
          id="outlined-basic-message"
          label="Votre message ..."
          value={message}
          fullWidth
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <Fab color="#ccc" aria-label="add">
          <SendIcon onClick={handleMessage} />
        </Fab>
      </Grid>
    </Grid>
  );

  const renderDesktop = (
    <Container>
      <Grid
        display="flex"
        container
        elevation={3}
        sx={{
          width: "100%",
        }}
      >
        <Grid
          item
          md={4}
          sx={{
            backgroundColor: "#D0E8EA",
            borderRight: "2px solid #e0e0e0",
          }}
        >
          <List>
            {/* bouclage des conversations */}
            <Typography variant="body2" p={1}>
              {" "}
              liste des utilisateurs connectés à cette annonce
            </Typography>
            <Divider />
            {conversations.length > 0 &&
              conversations.map((conversation, index) => (
                <ListItem
                  button
                  key={conversation.id}
                  onClick={() => handleConversation(conversation, index)}
                >
                  <ListItemIcon>
                    <Avatar
                      alt=""
                      //    src={`${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {conversation.author.email}
                        </Typography>
                      </>
                    }
                  ></ListItemText>
                  {/* <ListItemText secondary="online" align="right"></ListItemText> */}
                </ListItem>
              ))}

            {/* fin bouclage des conversations */}
          </List>
        </Grid>

        <Grid item md={8}>
          {blockMessage}
        </Grid>
      </Grid>
      <Grid> {blockSender} </Grid>
      <Grid container>
        <Grid item xs={6} p={1}>
          <ShButton variant="contained" size="large">
            Je Restitue en main propre
          </ShButton>
        </Grid>
      </Grid>
    </Container>
  );

  const renderMobile = (
    <Container>
      <Grid>
        <Button
          variant="outlined"
          onClick={() => setOpenConv(!openConv)}
          startIcon={<QuestionAnswerIcon />}
        >
          contacts
        </Button>
        <Drawer
          sx={{ zIndex: 10000 }}
          anchor="left"
          open={openConv}
          onClick={() => setOpenConv(!openConv)}
        >
          <Grid
            item
            md={4}
            sx={{
              borderRight: "1px solid #e0e0e0",
            }}
          >
            <Typography>Demandeurs </Typography>
            {conversations.length > 0 &&
              conversations.map((conversation, index) => (
                <ListItem
                  button
                  key={conversation.id}
                  onClick={() => handleConversation(conversation, index)}
                >
                  <ListItemIcon>
                    <Avatar
                      alt=""
                      //    src={`${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={conversation.author.email}
                  ></ListItemText>
                  {/* <ListItemText secondary="online" align="right"></ListItemText> */}
                </ListItem>
              ))}
          </Grid>
        </Drawer>
        {blockMessage}
        <Divider />
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            left: 0,
            backgroundColor: "#4abdf073",
          }}
        >
          {blockSender}
        </Box>
      </Grid>
    </Container>
  );

  return <>{isMatchSm ? <>{renderMobile} </> : <>{renderDesktop}</>}</>;
}
