import React from "react";
import {
  Box,
  Container,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Grid,
  Card,
  List,
  ListItemText,
  Chip,
  useTheme,
  useMediaQuery,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
//import Label from 'src/components/Label';
//import { ColorPreview } from 'src/components/color-utils';
import { display } from "@mui/system";
export default function Adetails({ annonce }) {
  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchMd = useMediaQuery(theme.breakpoints.down("lg"));

  const url = process.env.REACT_APP_PUBLIC_URL;
  const {
    id,
    title,
    filePath,
    status = 1,
    category,
    annonceAttributes,
  } = annonce;
  const image = `${url}/images/annonces/${filePath}`;
  const publicAttributes = annonceAttributes.filter(
    (att) => att.isSecret === false
  );
  const secretAttributes = annonceAttributes.filter(
    (att) => att.isSecret === true
  );

  const ProductImgStyle = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  });

  const renderDesktop = (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignContent: "center" }}
    >
      <Box pl={5} pt={2}>
        <Typography variant="h3" pb={3}>
          {category.name} {id} 
        </Typography>

        <Stack
          direction="row"
          pb={1}
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          {publicAttributes.map((attribute) => {
            return (
              <>
                {attribute.uiType === "colorcheckbox" ? (
                  <>
                    <Chip
                      key={attribute.id}
                      label={` ${attribute.uiLabel} `}
                      sx={{
                        color: "#fff",
                        backgroundColor: attribute.uiValue,
                        marginBottom: "3px",
                        marginRight: "2px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Chip
                      key={attribute.id}
                      variant="outlined"
                      label={`${attribute.attributeValue}`}
                      sx={{
                        color: "#1b9098",
                      }}
                    />
                  </>
                )}
              </>
            );
          })}
        </Stack>

        <Card sx={{ width: "70%", border: "1px solid #ccc" }}>
          <Box sx={{ pt: "100%", position: "relative" }}>
            {/* {status && (
            <Label
              variant="filled"
              color={(status === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {status}
            </Label>
          )} */}
            <ProductImgStyle alt={category.name} src={image} />
          </Box>

          <Stack
            sx={{
              padding: "5px",
              backgroundColor: "#D0E8EA",
            }}
          >
            <Stack pl={5}>
              <Typography variant="h4">Détails Secrets</Typography>
              {secretAttributes.map((attribute) => {
                return (
                  <Stack key={attribute.id}>
                    <List disablePadding>
                      <ListItemText
                        primary={attribute.attribute}
                        secondary={attribute.attributeValue}
                      />
                    </List>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Container>
  );

  const renderMobile = (
    <Container>
      <Paper elevation={2}>
        <Grid container p={2}>
          <Grid xs={3}>
            <img alt={category.name} src={image} width="50px" />
          </Grid>
          <Grid xs={9}>
            <Typography>Annonce</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );

  return <>{isMatchSm ? <>{renderMobile} </> : <>{renderDesktop}</>}</>;
}
