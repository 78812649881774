import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext } from "react";
import AnnonceContext from "../../../contexts/AnnonceContext";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

export default function CustomStepper({ children }) {
  const steps = ["Date et Lieu", "Objet", "Photo et détails", "Infos secretes"];
  const stepDescription = [
    "Quand et où ?",
    "Quoi ?",
    "Ajouter une photo",
    "Détails d'authentification supplémentaires",
  ];
  const stepComponent = [<Step1 />, <Step2 />, <Step3 />, <Step4 />];
  const { activeStep, setActiveStep } = useContext(AnnonceContext);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid>
      <Paper
        elevation={0}
        sx={{
          padding: isMatchSm ? 0 : 5,
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((step, i) => (
            <Step key={i}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography variant="h5" sx={{ marginTop: 1, marginBottom: 1 }}>
          {stepDescription[activeStep]}
        </Typography>
        {stepComponent[activeStep]}

        {children}

        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-around",
            justifySelf: "flex-end",
            marginTop: "20px",
            marginBottom: "5px",
          }}
        >
          <Button
            onClick={handleBack}
            disabled={activeStep === 0}
            variant="contained"
          >
            Précédent
          </Button>
          <Button
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
            variant="contained"
          >
            Suivant
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
}
