import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import AnnonceContext from "../../../../contexts/AnnonceContext";

export default function Switch({ name , isSecret = false}) {
  const {  setFormAttributes } = useContext(AnnonceContext);
  const [value, setValue] = useState(false);

  const handleCheck = () => {
    setFormAttributes((formAttributes) => [
      ...formAttributes.filter((att) => att.attribute !== name),
      {
        attribute: name,
        attributeValue: !value === true ? "Oui" : "Non",
        isSecret: isSecret,
      },
    ]);
    setValue(!value);
  };

  return (
    <Form.Check
      type="switch"
      id="custom-switch"
      label={name}
      onChange={() => handleCheck()}
    />
  );
}
