import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Validate() {
  const [info, setInfo] = useState([]);
  const { token } = useParams() || null;
  const url = `${process.env.REACT_APP_PUBLIC_API}/users/${token}/validate`;
  const navigate = useNavigate();
  try {
    const data = axios.patch(url).then((response) => setInfo(response.data));
    navigate("/login");
  } catch (error) {
    console.error(error);
  }

  return <div> {info.message} </div>;
}
