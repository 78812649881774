import React from "react";
import {
  Box,
  Container,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Grid,
  Card,
  List,
  ListItemText,
  Chip,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { display } from "@mui/system";
export default function Adetails({ annonce }) {
  const url = process.env.REACT_APP_PUBLIC_URL;
  const {
    id,
    title,
    filePath,
    status,
    ville,
    category,
    annonceAttributes,
  } = annonce;
  const image = category.isImageFrontHidden
    ? `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${category.defaultImage}`
    : `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${filePath}`;
  const publicAttributes = annonceAttributes.filter(
    (att) => att.isSecret === false
  );
  const secretAttributes = annonceAttributes.filter(
    (att) => att.isSecret === true
  );

  const ProductImgStyle = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "absolute",
  });

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignContent: "center" }}
    >
      <Box pl={2} pt={2}>
        <Typography variant="body1">
          {status} - {ville}
        </Typography>
        <Stack spacing={2}>
          <Typography
            sx={{ display: "flex", justifyContent: "space-between" }}
            variant="subtitle"
            noWrap
          >
            <Typography variant="h4"> {category.name} </Typography>
            {/* <Chip label={id} color="primary" /> */}
          </Typography>

          <Stack
            direction="row"
            pb={1}
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            {publicAttributes.map((attribute) => {
              return (
                <>
                  {attribute.uiType === "colorcheckbox" ? (
                    <>
                      <Chip
                        key={attribute.id}
                        label={` ${attribute.uiLabel} `}
                        sx={{
                          color: "#fff",
                          backgroundColor: attribute.uiValue,
                          marginBottom: "3px",
                          marginRight: "2px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Chip
                        key={attribute.id}
                        variant="outlined"
                        label={`${attribute.attributeValue}`}
                        sx={{
                          color: "#1b9098",
                        }}
                      />
                    </>
                  )}
                </>
              );
            })}
          </Stack>
        </Stack>
        <Card sx={{ width: "100%", border: "0.5px solid #ccc" }}>
          <Box sx={{ pt: "67%", position: "relative" }}>
            <ProductImgStyle alt={category.name} src={image} />
          </Box>

          <Stack>
            <Stack pl={2}>
              <Typography variant="h5">Description</Typography>
              {publicAttributes.map((attribute) => {
                return (
                  <Stack>
                    <List disablePadding>
                      <ListItemText
                        primary={attribute.uiLabel}
                        secondary={attribute.attributeValue}
                      />
                    </List>
                    <Divider />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Container>
  );
}
