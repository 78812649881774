import React, { useContext } from 'react'
import AnnonceContext from '../../../../contexts/AnnonceContext';
import Attribute from './Attribute';

export default function AttributeSelector({isSecret}) {
    const { currentCategory  } = useContext(AnnonceContext);
  return (
      <>
    {currentCategory !== undefined  && (
        <>
        <h2>{currentCategory.name}</h2>
        <Attribute isSecret={isSecret}/>
        </>
        )}
        </>
  
    
  )
}
