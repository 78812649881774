import React, { useContext } from "react";
import AnnonceContext from "../../../../contexts/AnnonceContext";
import Colorcheckbox from "./colorcheckbox/colorcheckbox";
import SelectField from "./SelectField";
import TextareaBox from "./TextareaBox";
import Textbox from "./Textbox";
import Switch from "./Switch";
import { Stack } from "@mui/material";

export default function Attribute({ isSecret = false }) {
  const { currentCategory } = useContext(AnnonceContext);
  const filterCategory = currentCategory.attribute.filter(
    (c) => c.isSecret === isSecret
  );
  const fieldToDisplay = (attribute) => {
    switch (attribute.ui) {
      case "select":
        return (
          <SelectField
            data={attribute.attributes}
            parent={attribute}
            isSecret={attribute.isSecret}
          />
        );
      case "colorcheckbox":
        return (
          <Colorcheckbox
            data={attribute.attributes}
            parent={attribute}
            isSecret={attribute.isSecret}
          />
        );
      case "textareabox":
        return (
          <TextareaBox
            data={attribute.attributes}
            name={attribute.name}
            isSecret={attribute.isSecret}
          />
        );
      case "checkbox":
        return (
          <Switch
            data={attribute.attributes}
            name={attribute.name}
            isSecret={attribute.isSecret}
          />
        );
      case "textbox":
        return <Textbox name={attribute.name} isSecret={attribute.isSecret} />;

      default:
        return null;
    }
  };

  return (
    <>
      {filterCategory !== undefined && (
     <Stack>
          {filterCategory.map((attribute, index) => {
            return (
              <Stack key={index} spacing={2}>
                {attribute.uiLabel}
                {fieldToDisplay(attribute)}{" "}
              </Stack>
            );
          })}
       </Stack>
      )}
    </>
  );
}
