import React, { useContext, useState } from "react";
import AnnonceContext from "../../../contexts/AnnonceContext";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import DaySelector from "./Forms/DaySelector";
import GoogleAddress from "../../../Components/GoogleAddress";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
export default function Step1() {
  const { formAnnonce, setFormAnnonce, date, setDate } = useContext(
    AnnonceContext
  );
 
  const handleChangeDate = (newDate) => {
    setDate(newDate);
    setFormAnnonce({ ...formAnnonce, date: newDate.toISOString() });
  };
  const handleStatus = (e) => {
    setFormAnnonce({
      ...formAnnonce,
      status: e.target.value,
      origin: e.target.value,
    });
  };
  const publishTitle =
    formAnnonce.status === "TROUVÉ" ? "J'ai trouvé" : "J'ai perdu";
  return (
    <>
      <Stack spacing={2}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="status-radio-buttons-group"
            name="status-radio-buttons-group"
            value={formAnnonce.status}
            onChange={handleStatus}
          >
            <FormControlLabel
              value="TROUVÉ"
              control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
              label="J'ai trouvé"
            />
            <FormControlLabel
              value="PERDU"
              control={
                <Radio checkedIcon={<SentimentVeryDissatisfiedIcon />} />
              }
              label="J'ai perdu"
            />
          </RadioGroup>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DesktopDatePicker
            label="Quand ?"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={(newDate) => {
              handleChangeDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <DaySelector setDate={setDate} date={date} />

        <GoogleAddress
          status="publish"
          setFormAnnonce={setFormAnnonce}
          formAnnonce={formAnnonce}
        />
      </Stack>
    </>
  );
}
