import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Link, Container, Typography, Box } from "@mui/material";
// hooks

// components

// sections
import RegisterForm from "../register/RegisterForm";
import useResponsive from "../../../../hooks/useResponsive";
import Logo from "../../../../Components/layout/Logo";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "top",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const [isSend, setIsSend] = useState(false);
  return (
    <RootStyle>
      <HeaderStyle></HeaderStyle>

      {mdUp && (
        <SectionStyle
          sx={{
            backgroundImage: "url(/images/site/right-teddy-sidebar.png)",
            margin: 0,
          }}
        >
          <Box sx={{ p: 5, color: "#fff" }}>
            <Logo width="400" color="white" />
            <Typography variant="h3" mt={5} textAlign="center">
              Bienvenue !
            </Typography>
          </Box>
        </SectionStyle>
      )}

      <Container>
        {
          !isSend ? (
            <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Créez un compte c'est gratuit !
            </Typography>
  
            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              Vous pourrez ainsi déposer des annonces ou contacter un annonceur
            </Typography>
  
            <RegisterForm isSend={isSend} setIsSend={setIsSend} />
  
            <Typography
              variant="body2"
              align="center"
              sx={{ color: "text.secondary", mt: 3 }}
            >
              en m'inscrivant j'accepte les ...
              <Link underline="always" color="text.primary" href="#"></Link>
              {""}et{""}
              <Link underline="always" color="text.primary" href="#"></Link>
            </Typography>
  
            <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
              vous avez déja un compte ?{" "}
              <Link variant="subtitle2" to="/login" component={RouterLink}>
                se connecter
              </Link>
            </Typography>
          </ContentStyle>


          ) : (
            <ContentStyle>

            <Typography variant="h3">Merci !</Typography>
            <Typography variant="h4">Vous allez recevoir un email de confirmation, à tout de suite !</Typography>
            
            <Link variant="h5" to="/login" component={RouterLink}>
                se connecter
              </Link>
            
            </ContentStyle>
          )
        }
      
      </Container>
    </RootStyle>
  );
}
