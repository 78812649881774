import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Iconify from "../../../Components/Iconify";
import Logo from "../../../Components/layout/Logo";

export default function ResetPassword() {
  const [info, setInfo] = useState([]);
  const { token } = useParams() || null;
  const url = `${process.env.REACT_APP_PUBLIC_API}/forgot-password/${token}`;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState({
    password: "",
  });
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const urlLogo = `/images/site/logo-black.svg`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (e) => {
    setPassword({ password: e.currentTarget.value });
  };
  const redeemButton = (
    <Button
      sx={{
        marginBottom: "30px",
      }}
      fullWidth
      variant="outlined"
      onClick={() => {
        navigate("/login/pass");
      }}
    >
      Refaire une demande de mot de passe ?
    </Button>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.password === "") return;
    try {
      axios
        .patch(url, password)
        .then((response) => {
          setInfo(response.data);
          navigate("/login");
        })
        .catch((error) =>
          setInfo(
            <Grid sx={12}>
              <Typography color="error">
                Problème ! le mot de passe n'est pas valide ou le lien a expiré
              </Typography>
              {redeemButton}
            </Grid>
          )
        );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Container maxWidth="800px">
        <Grid display="flex" alignItems="center" flexDirection="column">
          <Grid m={3} sx={12}>
            <img src={urlLogo} width="100%" />
          </Grid>
          {info}

          <Typography>Tapez un nouveau mote de passe</Typography>

          <form autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              autoComplete=""
              type={showPassword ? "text" : "password"}
              label="Mot de passe"
              name="password"
              id="password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography>
              le mot de passe doit comporter des minuscules, une majuscule, un
              chiffre et un caractère spécial
            </Typography>
            <LoadingButton
              fullWidth
              sx={{ marginTop: "20px" }}
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Recréer le mot de passe
            </LoadingButton>
          </form>
        </Grid>
      </Container>
    </>
  );
}
