
import React from 'react';
// material
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------



export default function ProductList({myAds, handleClickOpenDialog}) {
  return (
    <Grid container spacing={3} >
      {myAds.map((ads) => (
        <Grid key={ads.id} item xs={12} sm={6} md={4} onClick={() => handleClickOpenDialog(ads)} sx={{cursor:"pointer"}}> 
          <ShopProductCard ads={ads}  />
        </Grid>
      ))}
    </Grid>
  );
}
