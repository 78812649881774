import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Adetails from "./Adetails";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";

export default function AnnonceDetails({ selectedAnnonce }) {

  const { user, isAuthenticated } = useContext(AuthContext);
  const { conversations } = selectedAnnonce;
  const [conversationExist, setConversationExist] = useState(false);
  const userId =
    null !== selectedAnnonce.user ? selectedAnnonce.user.id : false;

  const ShButton = styled(Button)`
    background-color: #1b9098;
    color: #fff;
    width: 80%;
    text-transform: uppercase;
    padding: 6px 12px;
    &:hover {
      background-color: #85c4c8;
    }
  `;

  const [conversation] = useState({
    annonce: `api/annonces/${selectedAnnonce.id}`,
    messages: [
      {
        content: "demarrage d'une nouvelle conversation",
      },
    ],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && null !== user) {
      conversations.forEach((element) => {
        if (element.author.id === user.id) {
          setConversationExist(true);
        }
      });
    }
  }, [conversationExist, conversation]);

  return (
    <>
      <Grid container pb={5}>
        <Grid item xs={12} md={6}>
          <Adetails annonce={selectedAnnonce} />

          {/* bouton message */}
          <Box
            mt={5}
            pl={5}
            pr={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            {!isAuthenticated && (
              <Button
                sx={{
                  backgroundColor: "#4ABDF0",
                  height: "100px",
                  width: "100%",
                }}
                variant="contained"
                onClick={() => {
                  window.localStorage.setItem(
                    "returnLinkMessage",
                    `/message/${selectedAnnonce.id}`
                  );
                  navigate(`/message/${selectedAnnonce.id}`);
                }}
              >
                <Stack>
                  <Typography>Vous pouvez Contacter l' annonceur</Typography>
                  <Typography>Connectez-vous ou créer un compte</Typography>
                </Stack>
              </Button>
            )}

            {isAuthenticated && (
              <Box>
                {!conversationExist && user.id !== userId && (
                  <Button
                    sx={{
                      backgroundColor: "#4ABDF0",
                      width: "100%",
                    }}
                    variant="contained"
                    onClick={() => navigate(`/message/${selectedAnnonce.id}`)}
                  >
                    Contacter L'annonceur
                  </Button>
                )}
                {conversationExist && (
                  <Button
                    sx={{
                      backgroundColor: "#4ABDF0",
                      width: "100%",
                    }}
                    variant="contained"
                    onClick={() => navigate("/messages")}
                  >
                    <Stack>
                      <Typography>
                        Vous avez déjà contacté l'annonceur
                      </Typography>
                      <Typography>Voir votre messagerie</Typography>
                    </Stack>
                  </Button>
                )}
              </Box>
            )}
            {null !== user && user.id === userId && (
              <Button
                sx={{
                  backgroundColor: "#4ABDF0",
                }}
                variant="contained"
                onClick={() => navigate("/mes-annonces")}
              >
                <Stack>
                  <Typography>Vous possedez cette Annonce !</Typography>
                  <Typography>Voir vos Annonces</Typography>
                </Stack>
              </Button>
            )}
          </Box>
          {/* fin bouton message */}
        </Grid>

        <Grid item xs={12} md={6} pt="200px"></Grid>
      </Grid>
    </>
  );
}
