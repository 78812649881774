import jwtdecode from "jwt-decode";
import axios from "axios";

function authenticate(credentials) {
  const ApiUrl = process.env.REACT_APP_PUBLIC_API + "/userlogin";
  return axios
    .post(ApiUrl, credentials)
    .then((response) => response.data.token)
    .then((token) => {
      window.localStorage.setItem("authToken", token);
      axios.defaults.headers["Authorization"] = "Bearer " + token;

      return true;
    });
}

function redeemPassWord(content) {
  const ApiUrl = process.env.REACT_APP_PUBLIC_API + "/forgot-password";
  return axios
    .post(ApiUrl, content)
    .then((response) => response.data)
    .then((data) => {
      return true;
    });
}

function logout() {
  window.localStorage.removeItem("authToken");
  delete axios.defaults.headers["Authorization"];
}

function setAxiosToken(token) {
  axios.defaults.headers["Authorization"] = "Bearer " + token;
}

function setup() {
  const token = window.localStorage.getItem("authToken");
  if (token) {
    const { exp: expirationToken } = jwtdecode(token);

    if (expirationToken * 1000 > new Date().getTime()) {
      setAxiosToken(token);
    } else {
      logout();
    }
  } else {
    logout();
  }
}

function isAuthenticated() {
  const token = window.localStorage.getItem("authToken");
  if (token) {
    const { exp: expirationToken } = jwtdecode(token);

    if (expirationToken * 1000 > new Date().getTime()) {
      return true;
    }
    //return true;
    return false;
  }
  return false;
}

export default {
  setup,
  logout,
  isAuthenticated,
  authenticate,
  redeemPassWord,
};
