import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import AnnonceSelector from "../../Components/Forms/AnnonceSelector";
import Layout from "../../Components/layout/Layout";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AppBar,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import AnnonceDetails from "./AnnonceDetails";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import AnnonceCard from "./ProductCard";
import CustomSpinner from "./CustomSpinner";

const initialState = {
  selectedCategory: null,
  setSelectedCategory: () => undefined,
  filteredAttributes: null,
  setFilteredAttributes: () => undefined,
  date: null,
  setDate: () => undefined,
};
export const FoundContext = createContext(initialState);

const Found = ({ status, customerId }) => {
  const [selectedAnnonce, setSelectedAnnonce] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [foundAds, setFoundAds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [date, setDate] = useState(false);
  const urlStatus = status ? `?status=${status}` : "";
  const [current, setCurrent] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [resultFilter, setResultFilter] = useState([]);
  const [nb, setNb] = useState(150);
  const [count, setCount] = useState({
    prev: 0,
    next: nb,
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpenDialog = (data) => {
    setSelectedAnnonce(data);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const url = customerId
    ? `${process.env.REACT_APP_PUBLIC_API}/customers/${customerId}/annonces${urlStatus}`
    : `${process.env.REACT_APP_PUBLIC_API}/annonces${urlStatus}`;

  // `${process.env.REACT_APP_PUBLIC_API}/customers/${customerId}/annonces?${urlStatus}`

  const fetchFoundAds = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get(url).then((response) => response.data);

      setFoundAds(data);
      setResultFilter(data);

      setCurrent(data.slice(count.prev, count.next));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const haveMore = () => {
    if (current.length === resultFilter.length) {
      setHasMore(false);
      return;
    }
    setCurrent(
      current.concat(resultFilter.slice(count.prev + nb, count.next + nb))
    );
    setHasMore(true);
    setCount((prevState) => ({
      prev: prevState.prev + nb,
      next: prevState.next + nb,
    }));
  };

  useEffect(() => {
    setCurrent([]);
    makeFilter();
  }, [selectedCategory, filteredAttributes, date]);

  useEffect(() => {
    fetchFoundAds();
  }, [customerId, status]);

  const makeFilter = () => {
    const filteredAnnonces = foundAds.filter((a) =>
      selectedCategory ? a.category.id === selectedCategory : a.category.id > 0
    );

    const filteredAnnonceByDate = filteredAnnonces.filter((a) =>
      date
        ? date.setHours(0, 0, 0, 0) === new Date(a.date).setHours(0, 0, 0, 0)
        : new Date(a.date) > 0
    );

    const finalFilter = filteredAnnonceByDate.filter((a) => {
      return filteredAttributes.every((attribute) => {
   
        return a.annonceAttributes.some((a) =>
          attribute.attributeValue !== -1
            ? a.attribute === attribute.attribute &&
              a.attributeValue.toLowerCase() ===
                attribute.attributeValue.toLowerCase()
            : a.attribute != null
        );
      });
    });

    setResultFilter(finalFilter);
    if (finalFilter.length === 0) setHasMore(false);
    setCurrent(finalFilter.slice(count.prev, count.next));
  };

  let pluriel = resultFilter.length > 1 ? "s" : "";
  const nbReponses =
    resultFilter.length > 0
      ? `${resultFilter.length} Résultat${pluriel}`
      : "Pas de resultats";
  return (
    <Layout customerId={customerId}>
      <FoundContext.Provider
        value={{
          selectedCategory,
          setSelectedCategory,
          filteredAttributes,
          setFilteredAttributes,
          date,
          setDate,
        }}
      >
        <Container
          maxWidth=""
          sx={{
            paddingTop: "20px",
          }}
        >
          <Container sx={{ display: { xs: "block", md: "none" } }}>
            <Grid
              mb={3}
              sx={12}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Typography variant="h5">
                {" "}
                {!isLoading ? nbReponses : ""}{" "}
              </Typography>
            </Grid>
          </Container>
          <AnnonceSelector />
          <Container sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Grid
              mb={3}
              sx={12}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Typography variant="h5">
                {" "}
                {!isLoading ? nbReponses : ""}{" "}
              </Typography>
            </Grid>
          </Container>
          <Divider />

          <InfiniteScroll
            dataLength={current.length}
            next={haveMore}
            hasMore={hasMore}
            loader={<CustomSpinner />}
          >
            {isLoading ? (
              <CustomSpinner />
            ) : (
              <Grid container spacing={2}>
                {current.length > 0 ? (
                  current.map((data) => {
                    return (
                      <Grid key={data.id} item xs={12} sm={6} md={3}>
                        <AnnonceCard
                          ads={data}
                          handleClickOpenDialog={handleClickOpenDialog}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <>
                    {" "}
                    <Container sx={{ display: "none" }}>
                      <Grid sx={12} display="flex">
                        <Typography variant="h5">
                          {" "}
                          Pas de réponses !{" "}
                        </Typography>
                      </Grid>
                    </Container>
                  </>
                )}
              </Grid>
            )}
          </InfiniteScroll>
          <Dialog
            fullScreen
            open={openDialog}
            onClose={handleCloseDialog}
            // TransitionComponent={Transition}
          >
            <AppBar
              sx={{ position: "relative", backgroundColor: "transparent" }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseDialog}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button color="inherit" onClick={handleCloseDialog}>
                  Fermer
                </Button>
              </Toolbar>
            </AppBar>
            <AnnonceDetails selectedAnnonce={selectedAnnonce} />
          </Dialog>
        </Container>
      </FoundContext.Provider>
    </Layout>
  );
};

export default Found;
