import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AnnonceContext from "../../../../contexts/AnnonceContext";
import {
  Avatar,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function CategorySelector() {
  const theme = useTheme();
  const isMatchSm = useMediaQuery(theme.breakpoints.down("md"));

  const {
    formAnnonce,
    setFormAnnonce,
    setCurrentCategory,
    setFormAttributes,
  } = useContext(AnnonceContext);
  const baseUrl = process.env.REACT_APP_BASEURL;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_PUBLIC_API + "/categories?parent=1&order[sort]=asc";

    axios.get(apiUrl).then((resp) => {
      setCategories(resp.data);
    });
  }, []);

  const handleCategory = (category) => {
    setFormAnnonce({
      ...formAnnonce,
      category: `api/categories/${category.id}`,
      filePath: `default/${category.defaultImage}`,
    });

    setCurrentCategory(category);
    setFormAttributes([]);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {categories.map((category) => {
            return (
              <Stack
                sx={{ marginRight: 1 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                key={category.id}
              >
                <Stack>
                  <IconButton onClick={() => handleCategory(category)}>
                    <Avatar
                      alt=""
                      src={`${baseUrl}/pictos/${category.image}.svg`}
                      sx={{ width: 68, height: 68 }}
                    />
                  </IconButton>
                  <Typography sx={{ fontSize: 12, textAlign: "center" }}>
                    {category.name}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
