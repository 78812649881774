import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import AnnonceContext from "../../../../contexts/AnnonceContext";

export default function TextareaBox({ name,isSecret = false }) {
  const { setFormAttributes } = useContext(AnnonceContext);
  return (
    <div>
      <Form.Control
        as="textarea"
        rows={3}
        onChange={(e) =>
          setFormAttributes((formAttributes) => [
            ...formAttributes.filter((att) => att.attribute !== name),
            {
              attribute: name,
              attributeValue: e.target.value,
              isSecret : isSecret,
            },
          ])
        }
      />
    </div>
  );
}
