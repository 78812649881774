import React from "react";

import { Link as RouterLink } from "react-router-dom";
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Badge,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// utils

// components
//import Label from '../../../components/Label';
import InventoryIcon from "@mui/icons-material/Inventory";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// ----------------------------------------------------------------------
const ColorBox = ({ color }) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        width: "16px",
        height: "16px",
        borderRadius: "3px",
        margin: "2px",
      }}
    />
  );
};


const ProductImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
  position: "absolute",
  zIndex:0
});

// ----------------------------------------------------------------------

export default function ShopProductCard({ ads, handleClickOpenDialog }) {
  const {
    id,
    title,
    filePath,
    status,
    annonceAttributes,
    category,
    date,
    ville,
  } = ads;
  const frenchdate = new Date(date);

  const annonceDate = frenchdate.toLocaleString("fr-FR", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  // const boxName = null !== stockage ? stockage.boxName : 'pas de stockage';
  const cover = `${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${filePath}`;
  const ShButton = styled(Button)`
    background-color: #1b9098;
    color: #fff;

    text-transform: uppercase;
    padding: 6px 12px;
    &:hover {
      background-color: #85c4c8;
    }
  `;

  return (
    <Card elevation={3}>
      <Box sx={{ pt: "100%", position: "relative" }}>
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
        <ProductImgStyle alt={title} src={cover} />
      </Box>
 
        <Stack className="blockAnnonce" spacing={2} sx={{ p: 3}}>
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            <Typography
              sx={{ display: "flex", justifyContent: "space-between" }}
              variant="subtitle"
              noWrap
            >
              <Typography variant="h5"> {category.name} </Typography>
              <Chip label={id} color="primary" />
            </Typography>
          </Link>
          <Divider />

          <Stack>
            <Typography variant="subtitle1">
              <Typography component="span" variant="body1"></Typography>
            </Typography>
            <List sx={{ padding: 0, margin: 0 }}>
              <ListItem sx={{ padding: 0, margin: 0 }}>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText
                  primary={annonceDate}
                  sx={{ padding: 0, margin: 0 }}
                />
              </ListItem>
            </List>
            <List>
              <ListItem sx={{ padding: 0, margin: 0 }}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary={ville} sx={{ padding: 0, margin: 0 }} />
              </ListItem>
            </List>
            <Typography>Détails</Typography>
            <Divider />
            {annonceAttributes
              .filter((att) => att.isSecret === false)
              .map((annonceAttribute, index) => (
                <List key={index} sx={{ padding: 0, margin: 0 }}>
                  <ListItem sx={{ padding: 0, margin: 0 }}>
                   
                  <ListItemText
                    className="details"
                    primary={annonceAttribute.uiLabel}
                    secondary={
                      annonceAttribute.uiType === "colorcheckbox" ? (
                        <ColorBox color={annonceAttribute.uiValue} />
                      ) : (
                        annonceAttribute.attributeValue
                      )
                    }
                    sx={{ padding: 0, margin: 0 }}
                  />
                  </ListItem>

             
             
                  <Divider />
                </List>
              ))}

            <Typography>Détails Secrets</Typography>
            <Divider />
            {/* boucle sur les attributs */}

            {annonceAttributes
              .filter((att) => att.isSecret === true)
              .map((secret, index) => (
                <List key={index} sx={{ padding: 0, margin: 0 }}>
                  <ListItem sx={{ padding: 0, margin: 0 }}>
                    <ListItemText
                      className="details"
                      primary={secret.attribute}
                      secondary={secret.attributeValue}
                      sx={{ padding: 0, margin: 0 }}
                    />
                  </ListItem>
                </List>
              ))}
          </Stack>
          {/* <ShButton variant="contained"  onClick={handleClickOpenDialog.bind(this, ads)}>Détail</ShButton> */}
        </Stack>
  
    </Card>
  );
}
