import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import AnnonceContext from "../../../contexts/AnnonceContext";
import AttributeSelector from "./Forms/AttributeSelector";

export default function Step4() {
  const { handleSubmit } = useContext(AnnonceContext);

  return (
    <>
      <Grid>
        <Typography>
          Indiquez ici tout détail supplémentaire vous permettant d'authentifier
          le bon propriétaire (Ils ne seront visibles que par vous !)
        </Typography>
      </Grid>

      <AttributeSelector isSecret={true} />

      <Grid display="flex" justifyContent="center" marginY={5}>
        <Button color="primary" onClick={handleSubmit}>
          Publiez votre annonce
        </Button>
      </Grid>
    </>
  );
}
