import { createContext } from "react";

export default createContext({
  activeStep: null,
  setActiveStep: () => undefined,
  formAnnonce: null,
  setFormAnnonce: () => undefined,
  currentCategory: null,
  setCurrentCategory: () => undefined,
  formAttributes: null,
  setFormAttributes: () => undefined,
  handleSubmit: () => undefined,
  imageUpload: null,
  setImageUpload: () => undefined,
  date: null,
  setDate: () => undefined,
  isComplete: null,
  setIsComplete: () => undefined,
});
